import {
  CombineTreatmentPlansDTO,
  TreatmentPlanDTO
} from '../../shared/model/treatment-plan'
import moment from 'moment/moment'

export function combineTreatmentPlans(
  data: TreatmentPlanDTO[]
): CombineTreatmentPlansDTO {
  let combineTreatmentPlans: CombineTreatmentPlansDTO = {
    morningShiftTime: false,
    dayShiftTime: false,
    nightShiftTime: false,
    morningVitalsData: {},
    dayVitalsData: {},
    nightVitalsData: {}
  }

  data?.forEach(t => {
    const isCheckDay =
      !!t?.days &&
      !!t?.days?.find(
        day =>
          day.toLowerCase() === moment(new Date()).format('ddd').toLowerCase()
      )
    if ((t && isCheckDay) || t.daily) {
      if (t.morningShiftTime) {
        combineTreatmentPlans.morningShiftTime = true
        combineTreatmentPlans = toCombineTreatmentPlansVitalsDTO(
          combineTreatmentPlans,
          t,
          'morningVitalsData'
        )
      }
      if (t.dayShiftTime) {
        combineTreatmentPlans.dayShiftTime = true
        combineTreatmentPlans = toCombineTreatmentPlansVitalsDTO(
          combineTreatmentPlans,
          t,
          'dayVitalsData'
        )
      }
      if (t.nightShiftTime) {
        combineTreatmentPlans.nightShiftTime = true
        combineTreatmentPlans = toCombineTreatmentPlansVitalsDTO(
          combineTreatmentPlans,
          t,
          'nightVitalsData'
        )
      }
    }
  })

  return combineTreatmentPlans
}

function toCombineTreatmentPlansVitalsDTO(
  data: CombineTreatmentPlansDTO,
  treatmentPlan: TreatmentPlanDTO,
  vitalsTime: any
) {
  if (treatmentPlan.BT) {
    // @ts-ignore
    data[vitalsTime].BT = true
  }
  if (treatmentPlan.BP) {
    // @ts-ignore
    data[vitalsTime].BP = true
  }
  if (treatmentPlan.RR) {
    // @ts-ignore
    data[vitalsTime].RR = true
  }
  if (treatmentPlan.HR) {
    // @ts-ignore
    data[vitalsTime].HR = true
  }
  if (treatmentPlan.SPO2) {
    // @ts-ignore
    data[vitalsTime].SPO2 = true
  }
  if (treatmentPlan.BG) {
    // @ts-ignore
    data[vitalsTime].BG = true
  }
  return data
}
