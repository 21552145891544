import moment from 'moment'
import { ShiftType } from '../../shared/model/shift-planer.model'

const separatorSymbol = '|'

export function isDifferentShift(currentShift: ShiftType): boolean {
  const previousShiftType = localStorage.getItem(
    'shiftPlaner' + '.' + 'currentShift'
  )
  if (!previousShiftType) {
    setNewShift(currentShift)
    return false
  }
  const parsedPreviousShift = previousShiftType.split(separatorSymbol)
  const isDifferentShiftName = currentShift !== parsedPreviousShift[0]
  if (isDifferentShiftName || parsedPreviousShift.length === 1) {
    setNewShift(currentShift)
    return isDifferentShiftName
  }
  if (
    currentShift === parsedPreviousShift[0] &&
    parsedPreviousShift.length === 2 &&
    Math.abs(moment().diff(parsedPreviousShift[1], 'hours', true)) >= 16
  ) {
    setNewShift(currentShift)
    return true
  }
  return isDifferentShiftName
}
function setNewShift(currentShift: ShiftType) {
  localStorage.setItem(
    'shiftPlaner' + '.' + 'currentShift',
    currentShift + separatorSymbol + new Date().toISOString()
  )
}
