import moment from 'moment'
import { TreatmentPlanDTO } from '../../shared/model/treatment-plan'

export function isTakingTodayVitalsRequired(tp: TreatmentPlanDTO): boolean {
  return (
    tp.scheduleType === 'daily' ||
    (tp.scheduleType === 'weekly' &&
      tp.days?.includes(
        moment(new Date()).format('ddd').toLocaleLowerCase()
      )) ||
    false
  )
}
